import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    zIndex: theme.zIndex.modal + 1
  }
}));

const Loader = () => {
  const classes = useStyles();

  return (
    <Box className={classes.loaderContainer}>
      <CircularProgress color='primary' size={60} thickness={4} />
    </Box>
  );
};

export default Loader;
