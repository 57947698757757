import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import IntlMessages from '../../utility/IntlMessages';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {grey} from '@material-ui/core/colors';
import {Fonts} from '../../../shared/constants/AppEnums';
import {DialogActions, DialogContent, DialogTitle, TextField} from '@material-ui/core';
import {Form, Formik, useField} from 'formik';
import * as yup from 'yup';
import clsx from 'clsx';
import {useDispatch, useSelector} from 'react-redux';
import {onChangePassword, onJWTAuthSignout} from 'redux/actions';
import useStyles from '../ThemeSetting/index.style';
import InfoView from '../InfoView';
const MyTextField = (props) => {
  const classes = useStyles(props);
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';
  return (
    <TextField
      {...props}
      {...field}
      fullWidth
      helperText={errorText}
      className={classes.root}
      error={!!errorText}
    />
  );
};
const validationSchema = yup.object({
  actualPwd: yup.string().required(<IntlMessages id='validation.nameRequired' />),
  newPwd: yup
    .string()
    .required(<IntlMessages id='validation.passwordRequired' />)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Doit contenir 8 catactères, lettre majuscule, lettre miniscule, un chiffre et un caractère spécial'
    ),
  confirmPwd: yup
    .string()
    .required(<IntlMessages id='validation.reTypePassword' />)
    .oneOf([yup.ref('newPwd')], <IntlMessages id='validation.passwordMisMatch' />)
});
const ChangePasswordDialog = () => {
  const useStyle = makeStyles({
    btn: {
      width: '100%',
      fontWeight: Fonts.MEDIUM
    },
    contentText: {
      color: grey[600]
    }
  });
  const classes = useStyle();
  const isUserFirstConnection = useSelector(({auth}) => auth.isUserFirstConnection);

  const dispatch = useDispatch();

  return (
    <Dialog
      maxWidth='sm'
      fullWidth
      open={isUserFirstConnection ? isUserFirstConnection : false}
      //onClose={() => onDeny(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <Box p={4} display='flex' justifyContent='center'>
        <DialogTitle id='alert-dialog-title'>Changer mot de passe</DialogTitle>
      </Box>
      <Formik
        validateOnChange={true}
        initialValues={{
          actualPwd: '',
          newPwd: '',
          confirmPwd: ''
        }}
        validationSchema={validationSchema}
        onSubmit={(data, {setErrors, resetForm, setSubmitting}) => {
          setSubmitting(true);
          dispatch(
            onChangePassword({
              old_password: data.actualPwd,
              new_password: data.newPwd
            })
          );
          setSubmitting(false);
          resetForm();
        }}>
        {({isSubmitting, setFieldValue, values}) => (
          <Form className={classes.form} noValidate autoComplete='off'>
            <DialogContent>
              <InfoView />
              <DialogContentText className={classes.contentText} id='alert-dialog-description'>
                <Box>Veuillez changer votre mot de passe</Box>
              </DialogContentText>

              <Box display='flex' justifyContent='center' mb={{xs: 5, xl: 8}}>
                <MyTextField
                  label={<IntlMessages id='actual.pwd' />}
                  //placeholder={<IntlMessages id='actual.pwd' />}
                  name='actualPwd'
                  type='password'
                  variant='outlined'
                  className={clsx(classes.myTextFieldRoot, classes.root)}
                />
              </Box>

              <Box display='flex' justifyContent='center' mb={{xs: 5, xl: 8}}>
                <MyTextField
                  label={<IntlMessages id='new.pwd' />}
                  name='newPwd'
                  type='password'
                  variant='outlined'
                  className={classes.myTextFieldRoot}
                />
              </Box>
              <Box display='flex' justifyContent='center' mb={{xs: 5, xl: 8}}>
                <MyTextField
                  label={<IntlMessages id='confirm.pwd' />}
                  name='confirmPwd'
                  type='password'
                  variant='outlined'
                  className={classes.myTextFieldRoot}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Box p={5} display='flex' width='100%' justifyContent='space-around'>
                <Box>
                  <Button
                    variant='contained'
                    className={classes.btn}
                    onClick={() => {
                      dispatch(onJWTAuthSignout());
                    }}>
                    <IntlMessages id='common.logout' />
                  </Button>
                </Box>
                <Box>
                  <Button variant='contained' className={classes.btn} type='submit' color='primary'>
                    <IntlMessages id='title.changePwd' />
                  </Button>
                </Box>
              </Box>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ChangePasswordDialog;
