import React from 'react';
import {authRole} from 'shared/constants/AppConst';
import AccountBalanceTwoToneIcon from '@mui/icons-material/AccountBalanceTwoTone';
const routesConfig = [
  {
    id: 'home',
    title: 'Home',
    messageId: 'sidebar.home',
    type: 'item',
    icon: 'home',
    url: '/dashboard',
    auth: authRole.visitor
  },

  {
    id: 'transactions.topnet',
    title: 'transactionsTopnet',
    messageId: 'sidebar.transactions.topnet',
    type: 'item',
    icon: 'view_list',
    auth: authRole.onlyVisitor,
    url: '/Historique-transactions'
  },
  {
    id: 'transactions.demande',
    title: 'transactions  demande',
    messageId: 'sidebar.transaction.demande',
    type: 'item',
    icon: 'view_list',
    auth: authRole.TunisePromo,
    url: '/Liste-En-Attente'
  },

  {
    id: 'transactions.booking',
    title: 'transactions  Booking',
    messageId: 'sidebar.transactions.TunisiePromo',
    type: 'item',
    icon: 'view_list',
    auth: authRole.TunisePromo,
    url: '/Liste-Reservation'
  },
  {
    id: 'transactions.vip',
    title: 'transactionsVip',
    messageId: 'sidebar.transactions.vip',
    type: 'item',
    icon: 'view_list',
    auth: authRole.onlyVisitorVip,
    url: '/Historique-topup-vip'
  },
  {
    id: 'raccourci',
    title: 'raccourci',
    messageId: 'sidebar.raccourci',
    type: 'item',
    icon: 'dashboard',
    url: '/raccourci',
    auth: authRole.user
  },

  {
    id: 'rechrage',
    title: 'recharge',
    messageId: 'sidebar.recharge',
    type: 'collapse',
    icon: 'phoneIphone',
    auth: authRole.user,
    children: [
      {
        id: 'rechargeTpoup',
        title: 'rechargeTpoup',
        messageId: 'sidebar.recharge.tpoup',
        type: 'item',
        url: '/Topup'
      },
      {
        id: 'rechargeHistory',
        title: 'rechargeHistory',
        messageId: 'sidebar.recharge.card.history',
        type: 'item',
        url: '/Historique-recharge'
      }
      // {
      //   id: 'rechargeDemands',
      //   title: 'rechargeDemands',
      //   messageId: 'sidebar.recharge.card.demands',
      //   type: 'item',
      //   url: '/demandes-Voucher',
      // },
    ]
  },

  {
    id: 'order',
    title: 'Command',
    messageId: 'sidebar.order',
    type: 'collapse',
    icon: 'view_list',
    auth: authRole.user,
    children: [
      {
        id: 'postOrder',
        title: 'postOrder',
        messageId: 'sidebar.order.post',
        type: 'item',
        url: '/ajouter_commande'
      }
      /*{
        id: 'orderHistory',
        title: 'HistoryOrder',
        messageId: 'sidebar.order.history',
        type: 'item',
        url: '/historique_commandes',
      },*/
    ]
  },

  {
    id: 'payment',
    title: 'payment',
    messageId: 'sidebar.payment',
    type: 'collapse',
    icon: 'feed',
    auth: authRole.user,
    children: [
      {
        id: 'paymenthistory',
        title: 'TeamList',
        messageId: 'sidebar.invoice.payment',
        type: 'item',
        url: '/Menu-paiement'
      },
      {
        id: 'paymentHistory',
        title: 'paymentHistory',
        messageId: 'sidebar.recharge.card.history',
        type: 'item',
        url: '/paiement-historique'
      },
      {
        id: 'paymentHistoryTopnet',
        title: 'paymentHistory',
        messageId: 'sidebar.recharge.card.history.topnet',
        type: 'item',
        url: '/paiement-Topnet'
      },
      {
        id: 'tiskertyEvent',
        title: 'paymentHistory',
        messageId: 'sidebar.tiskert.Ticket',
        type: 'item',
        url: '/paiement-Ticket'
      }
    ]
  },
  // {
  //   id: 'rechrageReservarion',
  //   title: 'rechargeWallet',
  //   messageId: 'sidebar.hotels',
  //   type: 'collapse',
  //   icon: 'hotel',
  //   auth: authRole.user,
  //   children: [
  //     {
  //       id: 'reservaionHotels',
  //       title: 'reservaionHotels',
  //       messageId: 'sidebar.hotels.reserv',
  //       type: 'item',
  //       url: '/Hotels',
  //     },
  //     {
  //       id: 'HistoriqueReservation',
  //       title: 'HistoriqueReservation',
  //       messageId: 'sidebar.hotels.history',
  //       type: 'item',
  //       url: '/Hotel-Historique',
  //     },
  //   ],
  // },
  {
    id: 'rechrageReservarion',
    title: 'rechargeWallet',
    messageId: 'sidebar.hotels',
    type: 'collapse',
    icon: 'hotel',
    auth: authRole.user,
    children: [
      {
        id: 'reservaionHotels',
        title: 'booking',
        messageId: 'sidebar.booking.search',
        type: 'item',
        url: '/Booking'
      },
      {
        id: 'BookingOnRequest',
        title: 'Demande',
        messageId: 'sidebar.booking.OnRequest',
        type: 'item',
        url: '/demande'
      },

      {
        id: 'HistoriqueReservation',
        title: 'HistoriqueReservation',
        messageId: 'sidebar.hotels.history',
        type: 'item',
        url: '/historique-reservation'
      }
    ]
  },
  // {
  //   id: 'reclamation',
  //   title: 'reclamation',
  //   messageId: 'sidebar.reclamation',
  //   type: 'collapse',
  //   icon: 'view_list',
  //   auth: authRole.user,
  //   children: [
  //     {
  //       id: 'addReclamation',
  //       title: 'addReclamation',
  //       messageId: 'sidebar.reclamation.add',
  //       // type: 'collapse',
  //       icon: 'phoneIphone',
  //       type: 'item',
  //       url: '/Add-reclamation',
  //     },
  //     {
  //       id: 'historyReclamation',
  //       title: 'HistoriqueReservation',
  //       messageId: 'sidebar.reclamation.history',
  //       // type: 'collapse',
  //       icon: 'feed',
  //       type: 'item',
  //       url: '/History-reclamation',
  //     },
  //   ],
  // },
  {
    id: 'rechrageWallet',
    title: 'rechargeWallet',
    messageId: 'sidebar.recharge.wallet',
    type: 'collapse',
    icon: 'account_balance_wallet',
    auth: authRole.user,
    children: [
      {
        id: 'rechargewallet',
        title: 'rechargewallet',
        messageId: 'sidebar.alimenter.wallet',
        type: 'item',
        url: '/Menu-recharge'
      },
      {
        id: 'rechargeWalletHistory',
        title: 'rechargeWalletHistory',
        messageId: 'sidebar.recharge.card.history',
        type: 'item',
        url: '/Historique-rechargeCarte'
      }
    ]
  },
  // {
  //   id: 'rechrageCard',
  //   title: 'rechargeCard',
  //   messageId: 'sidebar.recharge.carte',
  //   type: 'collapse',
  //   icon: 'theaters',
  //   auth: authRole.user,
  //   children: [
  //     {
  //       id: 'rechargecard',
  //       title: 'rechargeCard',
  //       messageId: 'sidebar.recharge.card',
  //       type: 'item',
  //       url: '/recharge/carte',
  //     },
  //     {
  //       id: 'rechargeWalletHistory',
  //       title: 'rechargeWalletHistory',
  //       messageId: 'sidebar.recharge.card.history',
  //       type: 'item',
  //       url: '/Historique-rechargeCarte',
  //     },
  //   ],
  // },
  {
    id: 'coupons',
    title: 'coupons',
    messageId: 'sidebar.coupons',
    type: 'collapse',
    icon: 'theaters',
    auth: authRole.user,

    children: [
      // {
      //   id: 'rechargewallet',
      //   title: 'rechargewallet',
      //   messageId: 'sidebar.coupons',
      //   type: 'item',
      //   url: '/coupons',
      // },
      {
        id: 'brands',
        title: 'Categories',
        messageId: 'sidebar.coupons',
        type: 'item',
        url: '/category'
      },
      {
        id: 'rechargeWalletHistory',
        title: 'rechargeWalletHistory',
        messageId: 'sidebar.recharge.card.history',
        type: 'item',
        url: '/Historique-Coupons'
      }
    ]
  },
  {
    id: 'extractWallet',
    title: 'extract',
    messageId: 'sidebar.parameters.extract',
    type: 'item',
    url: '/extrait',
    icon: 'view_list',
    auth: authRole.user
  },
  {
    id: 'parameters',
    title: 'parameters',
    messageId: 'sidebar.parameters',
    type: 'collapse',
    icon: 'settingscell',
    auth: authRole.user,
    children: [
      {
        id: 'changePwd',
        title: 'changePwd',
        messageId: 'sidebar.parameters.changepwd',
        type: 'item',
        url: '/change-password'
      },
      {
        id: 'balance transfer',
        title: 'balance transfer',
        messageId: 'sidebar.parameters.balancetransfert',
        type: 'item',
        url: '/balanceTransfert'
      }
    ]
  },
  {
    id: 'reclamation',
    title: 'Reclamation',
    messageId: 'remboursement.managment',
    type: 'collapse',
    icon: <AccountBalanceTwoToneIcon />,
    auth: authRole.user,
    children: [
      {
        id: 'addRemboursement',
        title: 'Add reclamation',
        messageId: 'sidebar.remboursement.add',
        type: 'item',
        url: '/Demande_Remboursement'
      },
      {
        id: 'GestionRemboursement',
        title: 'Gestion remboursement',
        messageId: 'sidebar.remboursement.History',
        type: 'item',
        url: '/Historique_Remboursement'
      }
    ]
  },
  {
    id: 'reclamation',
    title: 'Reclamation',
    messageId: 'Gestion de Reclamation',
    type: 'collapse',
    icon: 'warning',
    auth: authRole.user,
    children: [
      {
        id: 'addReclamation',
        title: 'Add reclamation',
        messageId: 'sidebar.reclamation.add',
        type: 'item',
        url: '/reclamation'
      },
      {
        id: 'GestionReclamation',
        title: 'Gestion reclamation',
        messageId: 'reclamation.managment',
        type: 'item',
        url: '/GestionReclamation'
      }
    ]
  },
  {
    id: 'about',
    title: 'about',
    messageId: 'sidebar.about',
    type: 'collapse',
    icon: 'info',
    auth: authRole.user,
    children: [
      {
        id: 'info',
        title: 'info',
        messageId: 'sidebar.parameters.info',
        type: 'item',
        url: '/info'
      }
    ]
  },
  {
    id: 'history',
    title: 'historytopup',
    messageId: 'sidebar.History',
    type: 'collapse',
    icon: 'view_list',
    auth: authRole.fournisseur,
    children: [
      {
        id: 'historyTopup',
        title: 'historyTpoup',
        messageId: 'feed.topup.valeurFiscale',
        type: 'item',
        url: '/Topup-Historique'
      },
      {
        id: 'HistoryVoucher',
        title: 'historyVoucher',
        messageId: 'common.voucher',
        type: 'item',
        url: '/Voucher-Historique'
      }
    ]
  },
  {
    id: 'stock',
    title: 'stocktopup',
    messageId: 'stock',
    type: 'collapse',
    icon: 'view_list',
    auth: authRole.fournisseur,
    children: [
      {
        id: 'stockTopup',
        title: 'stockTpoup',
        messageId: 'feed.topup.valeurFiscale',
        type: 'item',
        url: '/Stock-Topup'
      },
      {
        id: 'HistoryVoucher',
        title: 'stockVoucher',
        messageId: 'common.voucher',
        type: 'item',
        url: '/Stock-Voucher'
      }
    ]
  }
];
export default routesConfig;
