import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const BookingRoute = [
  {
    auth: authRole.user,
    routes: [
      {
        path: '/demande',
        component: React.lazy(() => import('./pages/demandeBooking'))
      },
      {
        path: '/Booking',
        component: React.lazy(() => import('./pages/Search'))
      },

      {
        path: '/Hotel',
        component: React.lazy(() => import('./pages/hotel'))
      },
      {
        path: '/Request',
        component: React.lazy(() => import('./pages/request'))
      },
      {
        path: '/Liste',
        component: React.lazy(() => import('./pages/liste'))
      },
      {
        path: '/historique-reservation',
        component: React.lazy(() => import('./pages/history'))
      }
    ]
  }
];
export const TunisiePromoRoute = [
  {
    auth: authRole.TunisePromo,
    routes: [
      {
        path: '/Liste-En-Attente',
        component: React.lazy(() => import('./pages/tunisiePromoCompte/DemandeList'))
      },
      {
        path: '/Liste-Reservation',
        component: React.lazy(() => import('./pages/tunisiePromoCompte/HistoriqueReservation'))
      }
    ]
  }
];
