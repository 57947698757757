import {makeStyles} from '@material-ui/core';
import {grey} from '@material-ui/core/colors';
import {Fonts} from 'shared/constants/AppEnums';

const useStyles = makeStyles((theme) => ({
  totalPrice: {
    fontSize: '20px',
    fontWeight: 700,
    margin: '0px 40px',
    color: '#0da4ff'
  },
  width: {width: '100%'},
  selectStyle: {
    width: '100%'
  },
  root: {
    '& .MuiFormControl-root': {
      width: '100%'
    },
    '& .MuiInputBase-root': {
      height: '55px',
      borderRadius: '4px',
      width: '100%',
      marginBottom: '10px',
      backgroundColor: '#ececec4d'
    },
    '& .MuiSelect-root': {
      backgroundColor: '#ececec4d',
      borderRadius: '8px',
      marginBottom: '10px'
    },

    '& .MuiFormLabel-root': {
      paddingLeft: '0px',
      paddingRight: '4px',
      font: 'italic normal 300 21px Poppins;',
      fontWeight: 400,
      lineHeight: 1
      //backgroundColor: '#ececec4d',
    },
    '& .MuiTypography-body1': {
      fontSize: '18px',
      marginRight: '20px'
    },
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: '#003250'
    }
  },
  container: {
    // border: 'solid',
    // borderColor: '#e8e6e6',
    // borderWidth: '1px',
    padding: theme.spacing(10),
    backgroundColor: 'white',
    marginTop: '30px',
    [theme.breakpoints.down('xs')]: {padding: 0, paddingTop: 30}
  },
  page: {
    padding: theme.spacing(2),
    backgroundColor: 'white',
    marginTop: '30px'
  },
  textField: {
    [theme.breakpoints.down('sm')]: {
      padding: '20',
      marginLeft: theme.spacing(0)
    }
  },
  mainBox: {
    paddingTop: '20px',
    padding: 20,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0
    }
  },
  formControl: {
    //  margin: theme.spacing(1),
    minWidth: 50,
    width: '100%'
  },
  buttonText: {
    [theme.breakpoints.up('sm')]: {
      width: '260px',
      marginBottom: theme.spacing(5)
    }
  },
  input: {
    display: 'none'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  submitButton: {
    backgroundColor: '#003255',
    width: '250px',
    height: '40px',
    color: 'white',
    marginTop: '30px'
  },
  dense: {
    marginTop: theme.spacing(4)
  },
  label: {font: 'italic normal 300 21px Poppins;'},
  upload: {paddingTop: '20px'},
  img: {
    width: '25px',
    marginRight: '30px'
  },
  title: {
    color: '#003255',
    fontSize: '18px',
    marginTop: 30
  },
  // root: {
  //   '& .MuiInputBase-root': {
  //     // borderColor: 'none',
  //     color: '#003255',
  //     backgroundColor: '#ffffff',
  //     height: '55px',
  //     borderRadius: '8px',
  //     width: '100%',
  //     //   border:'solid',
  //     backgroundColor: '#ececec4d',
  //     // borderWidth:'0.5px'
  //   },
  //   '& .MuiFormLabel-root': {
  //     color: '#003255',
  //     paddingLeft: '0px',
  //     //width:'500px',
  //     paddingRight: '40px',
  //     font: 'italic normal 300 21px/33px Poppins;',
  //     fontWeight: 400,
  //     lineHeight: 1,
  //     backgroundColor: 'none',
  //   },
  // },

  formRoot: {
    textAlign: 'left',
    [theme.breakpoints.up('xl')]: {
      marginBottom: 24
    }
  },
  myTextFieldRoot: {
    width: '100%',
    borderRadius: '4px',
    backgroundColor: '#ececec4d',
    height: '100px',
    padding: '10px',
    font: 'italic normal 300 21px Poppins;'
  },
  checkboxRoot: {
    marginLeft: -12
  },
  pointer: {
    cursor: 'pointer'
  },
  btnRoot: {
    borderRadius: theme.overrides.MuiCard.root.borderRadius,
    width: '10rem',
    fontWeight: Fonts.REGULAR,
    fontSize: 16,
    margin: 6,
    textTransform: 'capitalize'
  },
  btnRootFull: {
    width: '100%'
  },
  dividerRoot: {
    marginBottom: 16,
    marginLeft: -48,
    marginRight: -48,
    [theme.breakpoints.up('xl')]: {
      marginBottom: 32
    }
  },
  textPrimary: {
    color: theme.palette.text.primary
  },
  colorTextPrimary: {
    color: theme.palette.primary.main
  },
  underlineNone: {
    textDecoration: 'none'
  },
  textGrey: {
    color: theme.palette.grey[500]
  },
  imgCard: {
    borderColor: '#ececec4d',
    border: 'solid',
    width: 150
  },
  boxTitle: {
    font: 'italic normal 300 28px Poppins;'
  },
  cardRoot: {
    maxWidth: '50rem',
    width: '100%',
    overflow: 'hidden',
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    position: 'relative',
    paddingTop: 20,
    [theme.breakpoints.up('xl')]: {
      paddingTop: 32
    }
  },

  infoRib: {
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',

    [theme.breakpoints.down('lg')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      justifyContent: 'center', // Remplacé "marginRight" par "justifyContent: 'flex-end'"
      alignItems: 'center',
      '& > *': {
        margin: '0 15px' // Ajoutez l'espace entre les éléments enfants
      }
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },

  ribCard: {
    boxShadow: '10 10px 15px 5px rgba(0, 0, 0, 0.1)',
    flexWrap: 'wrap',
    width: '23%',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column !important',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    fontSize: '20px',
    padding: '20px',
    marginTop: 5,
    [theme.breakpoints.down('md')]: {width: '300px'}
  },
  imgRib: {
    width: '100%',
    height: '90%',
    objectFit: 'contain'
  },
  ribBoxImg: {
    marginBottom: '10px',
    width: '100%',
    height: '150px',
    objectFit: 'contain'
  },
  cardInfo: {
    maxWidth: '125rem', // Réglez une largeur maximale pour les écrans plus larges
    width: '100%', // Utilisez une largeur relative pour la boîte parente
    height: '120%',
    overflow: 'hidden',
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
    // textAlign: 'center',
    position: 'relative',
    paddingTop: '5%',
    [theme.breakpoints.up('xl')]: {
      maxWidth: '125rem',
      paddingTop: '3%'
      /* Ajoutez plus de styles pour les écrans extra-larges si nécessaire */
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '30rem',
      paddingTop: '4%',
      paddingBottom: '4%',
      paddingLeft: '4%',
      paddingRight: '4%'
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '20rem',
      paddingTop: '6%',
      paddingBottom: '6%',
      paddingLeft: '6%',
      paddingRight: '6%'
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '15rem',
      paddingTop: '8%',
      paddingBottom: '8%',
      paddingLeft: '8%',
      paddingRight: '8%'
    }
  },
  cardContact: {
    borderRadius: '20px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%', // Utilisez une largeur relative pour la boîte parente
    maxWidth: '100%',
    // minWidth: '89%',
    //margin: 'auto', // Centrez la carte horizontalement
    height: '100%',
    overflow: 'hidden',
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    position: 'relative',
    // paddingTop: '5%',
    [theme.breakpoints.down('sm')]: {
      minWidth: '20rem',
      paddingTop: '6%',
      paddingBottom: '6%',
      paddingLeft: '6%',
      paddingRight: '6%',
      justifyContent: 'center',
      alignItems: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '15rem',
      paddingTop: '8%',
      paddingBottom: '8%',
      paddingLeft: '8%',
      paddingRight: '8%',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  cardRoot2: {
    maxWidth: '100rem',
    height: '50rem',
    width: '100%',
    overflow: 'hidden',
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    position: 'relative',
    paddingTop: '50px',
    paddingBottom: '50px',
    [theme.breakpoints.up('xl')]: {
      paddingTop: 30,
      paddingBotttom: 30,
      height: '50rem'
    }
  },
  cardRootProfile: {
    maxWidth: '85rem',
    width: '100%',
    borderRadius: '20px',
    overflow: 'hidden',
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    position: 'relative',
    paddingTop: '0.2px',
    [theme.breakpoints.up('xl')]: {
      paddingTop: 0.2
    }
  },
  cardRootProfile1: {
    maxWidth: '85rem',
    width: '100%',
    overflow: 'hidden',
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    paddingTop: '0.2px',
    [theme.breakpoints.up('xl')]: {
      paddingTop: 0.2
    }
  },
  twobxes: {
    //paddingTop: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  boxProfile: {
    // paddingTop: 8,
    flexWrap: 'wrap',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  avatar: {
    width: '14%',
    height: '10%',
    marginBottom: 10,
    marginTop: 30,
    border: 'solid',
    borderColor: '#13294e',
    flexWrap: 'wrap',
    display: 'flex',
    flexDirection: 'column'
  },
  borderBottomClass: {
    borderBottom: `1px solid ${grey[300]}`,
    backgroundColor: 'rgb(26 112 255 / 50%)'
  },
  boxRowColumn: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {flexDirection: 'column'}
  },
  boxRowColumn1: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },

  parag: {
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',

    flexDirection: 'column',
    width: '250px',
    fontSize: '20px',
    padding: 15,
    margin: 15
  },
  paragContact: {
    display: 'flex',
    justifyContent: 'center',

    flexDirection: 'column',
    fontSize: '21px',
    padding: 10,
    margin: 20,
    marginLeft: '60px'
  },
  imgBox: {
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    fontSize: '20px',
    padding: 15,
    margin: 15,
    flexDirection: 'column',
    width: '19vw',
    [theme.breakpoints.down('md')]: {width: '300px'}
  },

  infoBox: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: '#48678226'
  },

  infoContact: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
    // backgroundColor: '#f7f8fc',
  },
  ///////////////////////// quoteRooms
  roomTitle: {
    fontSize: '18px',
    color: '#003255'
  },
  rooms: {
    fontSize: '20px'
    //  color: '#003255',
  },
  roomValue: {marginLeft: '5px', fontSize: 18, fontWeight: 'bold'},
  bookBtn: {
    padding: '6px 8px',
    fontSize: '18px',
    color: 'white',
    width: '19vw',
    backgroundColor: '#3884bbde',
    borderRadius: '25px',
    [theme.breakpoints.down('sm')]: {width: '100%'},
    '&:hover': {
      backgroundColor: '#154166'
    }
  },
  roomIndex: {
    fontSize: '18px'
  },
  formBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {flexDirection: 'column'}
  },
  formTitle: {
    fontSize: '20px',
    fontFamily: 'Roboto',
    color: '#575757',
    [theme.breakpoints.down('sm')]: {fontSize: '16px'}
  }
}));
export default useStyles;
