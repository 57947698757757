import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const topnetPageConfig = [
  {
    auth: authRole.onlyVisitor,
    routes: [
      {
        path: '/Historique-transactions',
        component: React.lazy(() => import('./Pages/transactions'))
      }
    ]
  }
];
