import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const rechargePagesConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        path: '/Topup',
        component: React.lazy(() => import('./Pages/index'))
      },
      {
        path: '/demandes-Voucher',
        component: React.lazy(() => import('./Pages/Demands'))
      },
      {
        path: '/Historique-recharge/:id?',
        component: React.lazy(() => import('./Pages/HistoryTabs'))
      }
    ]
  }
];
