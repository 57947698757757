import dataAxios from '@crema/services/data/axiosData';
import {appIntl} from '@crema/utility/Utils';

import {
  FETCH_START,
  FETCH_SUCCESS,
  GET_ALL_TOPUP_TRANS,
  GET_ALL_TOPUP_OPERATORS_RETAILER,
  GET_ALL_VOUCHER_TRANSACTIONS,
  GET_ALL_OPERATORS_RETAILER,
  GET_ALL_TOPUP_STOCK,
  SET_STOCK_BY_OPERATOR,
  GET_ALL_VOUCHER_STOCK,
  GET_EMPTY_DOCUMENTS,
  GET_STOCK_VOUCHER,
  GET_HISTORY_REIMBURSED,
  GET_HISTORY_REIMBURSED_FAILED
} from 'shared/constants/ActionTypes';
import {fetchError, fetchStart} from './Common';
export const getStockVoucher = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/voucher/getStock', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_STOCK_VOUCHER,
            payload: res.data
          });
          dispatch({
            type: FETCH_SUCCESS,
            payload: 'Settings retrieved successfully'
          });
          // }
          //  else {
          //   // dispatch({
          //   //   type: GET_EMPTY_DOCUMENTS,
          //   //   payload: [],
          //   // });
          //   dispatch({
          //     type: fetchError(),
          //     payload: 'Pas de données trouvées',
          //   });
          // }
        } else {
          dispatch({
            type: fetchError(),
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: fetchError()});
      });
  };
};

export const getAllVoucherStock = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/voucher/stock/document', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.code === 2202) {
            dispatch({
              type: GET_ALL_VOUCHER_STOCK,
              payload: res.data.data
            });
          } else if (res.data.code === 2203) {
            dispatch({
              type: GET_EMPTY_DOCUMENTS,
              payload: {items: [], totalPages: 0}
            });
          }
        } else {
          dispatch({
            type: fetchError(),
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: fetchError()});
      });
  };
};

export const getStockTopupHistory = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/topUpKh/operator', {params: params})
      .then((res) => {
        if (res.status === 200) {
          if (res.data.message === 'Operators retrieved') {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_ALL_TOPUP_STOCK,
              payload: res.data.data
            });
            dispatch({
              type: SET_STOCK_BY_OPERATOR,
              payload: res.data.data
            });
          } else {
            dispatch({
              type: GET_ALL_TOPUP_STOCK,
              payload: []
            });
          }
        } else {
          dispatch({
            type: fetchError(),
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: fetchError()});
      });
  };
};

export const getAllVoucherTrancations = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/voucher/transaction', {params: params})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          if (res.data.message === 'List of transactions.') {
            dispatch({
              type: GET_ALL_VOUCHER_TRANSACTIONS,
              payload: res.data.data
            });
            dispatch({
              type: FETCH_SUCCESS,
              payload: 'Settings retrieved successfully'
            });
          } else {
            dispatch({
              type: fetchError(),
              payload: 'Pas de données trouvées'
            });
          }
        } else {
          dispatch({
            type: fetchError(),
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: fetchError()});
      });
  };
};
export const getAllOperators = (params) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/voucher/operator', {params: params})
      .then((res) => {
        dispatch({type: FETCH_SUCCESS});
        if (res.status === 200) {
          if (res.data.message === 'Operator retrieved') {
            dispatch({
              type: GET_ALL_OPERATORS_RETAILER,
              payload: res.data.data
            });
          } else {
            dispatch({
              type: GET_ALL_OPERATORS_RETAILER,
              payload: {
                items: [],
                totalPages: 0
              }
            });
          }
        } else {
          dispatch({
            type: fetchError(),
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: fetchError()});
      });
  };
};
export const getAllTopupOperators = (params) => {
  const {messages} = appIntl();

  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/topUpKh/operator', {params: params})
      .then((res) => {
        dispatch({type: FETCH_SUCCESS});
        if (res.status === 200) {
          if (res.data.message === 'Operators retrieved') {
            dispatch({
              type: GET_ALL_TOPUP_OPERATORS_RETAILER,
              payload: res.data.data
            });
          } else {
            dispatch({
              type: GET_ALL_TOPUP_OPERATORS_RETAILER,
              payload: {
                items: [],
                totalPages: 0
              }
            });
          }
        } else {
          dispatch({
            type: fetchError(),
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: fetchError()});
      });
  };
};
export const getAllTopupHistory = (params) => {
  console.log(
    '*****************************************************************************************',
    params
  );
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch(fetchStart());
    dataAxios
      .get('/topUpKh/transaction', {params: params})
      .then((res) => {
        if (res.status === 200) {
          if (res.data.message === 'List of Transactions') {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_ALL_TOPUP_TRANS,
              payload: res.data.data
            });
          } else {
            dispatch({
              type: GET_ALL_TOPUP_TRANS,
              payload: []
            });
          }
        } else {
          dispatch({
            type: fetchError(),
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({type: fetchError()});
      });
  };
};
//GET HISTORY REIMBURSED

export const getHistorReimbursed = (params) => {
  const {messages} = appIntl();
  return async (dispatch) => {
    dispatch(fetchStart());
    await dataAxios
      .get('/wallet/feed', {params: params})
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 'success' && res.data.message === 'feeds retrieved') {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: GET_HISTORY_REIMBURSED,
              payload: res.data.data
            });
          } else {
            dispatch({
              type: GET_HISTORY_REIMBURSED_FAILED,
              payload: []
            });
          }
        } else {
          dispatch({
            type: fetchError(),
            payload: messages['message.somethingWentWrong']
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: fetchError(),
          payload: messages['message.somethingWentWrong']
        });
      });
  };
};
