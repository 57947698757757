export const ProductColors = {
  BLUE: '#e4f5f4',
  GREY: '#f3e6ff',
  PARROT: '#ffe5c2',
  LIGHT_PINK: '#ffd6d8',
  PINK: '#fcfca2',
  LIGHT_GREEN: '#c9ffc2',
  LIGHT_blue: '#defff3'
};
export const ecommerceData = [
  {
    id: 1,
    title: 'Topup',
    description:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters',
    mrp: '120',
    discount: '25',
    rating: 5,
    ideaFor: 1,
    brand: 1,
    color: ProductColors.BLUE,
    reviews: 2000,
    image: [
      {
        id: 3332,
        src: 'https://via.placeholder.com/130x215'
      },
      {
        id: 43554,
        src: 'https://via.placeholder.com/130x215'
      },
      {
        id: 434324,
        src: 'https://via.placeholder.com/130x215'
      },
      {
        id: 33245,
        src: 'https://via.placeholder.com/130x215'
      }
    ]
  },
  {
    id: 2,
    title: 'Feed card',
    description:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters',
    mrp: '125',
    brand: 4,
    ideaFor: 3,
    discount: '20',
    rating: 4,
    color: ProductColors.PINK,
    reviews: 4356,
    image: [
      {
        id: 3,
        src: 'https://via.placeholder.com/130x215'
      },
      {
        id: 43,
        src: 'https://via.placeholder.com/130x215'
      },
      {
        id: 23,
        src: 'https://via.placeholder.com/130x215'
      },
      {
        id: 54,
        src: 'https://via.placeholder.com/130x215'
      }
    ]
  },
  {
    id: 3,
    title: 'Voucher',
    description:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters',
    mrp: '100',
    ideaFor: 4,
    brand: 1,
    discount: '30',
    rating: 4,
    color: ProductColors.BLUE,
    reviews: 235,
    image: [
      {
        id: 4343,
        src: 'https://via.placeholder.com/130x215'
      },
      {
        id: 323243,
        src: 'https://via.placeholder.com/130x215'
      },
      {
        id: 325432,
        src: 'https://via.placeholder.com/130x215'
      },
      {
        id: 653323,
        src: 'https://via.placeholder.com/130x215'
      }
    ]
  },
  {
    id: 3,
    title: 'Factures',
    description:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters',
    mrp: '100',
    ideaFor: 4,
    brand: 1,
    discount: '30',
    rating: 4,
    color: ProductColors.BLUE,
    reviews: 235,
    image: [
      {
        id: 4343,
        src: 'https://via.placeholder.com/130x215'
      },
      {
        id: 323243,
        src: 'https://via.placeholder.com/130x215'
      },
      {
        id: 325432,
        src: 'https://via.placeholder.com/130x215'
      },
      {
        id: 653323,
        src: 'https://via.placeholder.com/130x215'
      }
    ]
  },
  {
    id: 3,
    title: 'Recharge ',
    description:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters',
    mrp: '100',
    ideaFor: 4,
    brand: 1,
    discount: '30',
    rating: 4,
    color: ProductColors.BLUE,
    reviews: 235,
    image: [
      {
        id: 4343,
        src: 'https://via.placeholder.com/130x215'
      },
      {
        id: 323243,
        src: 'https://via.placeholder.com/130x215'
      },
      {
        id: 325432,
        src: 'https://via.placeholder.com/130x215'
      },
      {
        id: 653323,
        src: 'https://via.placeholder.com/130x215'
      }
    ]
  }
];
export default ecommerceData;
