const {
  POST_SERVICE,
  POST_CATEGORY,
  GET_SUPPLIER,
  GET_INTERVENANT,
  GET_SERVICE,
  GET_SERVICE_BY_ID,
  GET_REIMBURSED,
  GET_CATEGORY,
  GET_ALL_SUPPLIER_SERVICE,
  GET_TOPUP_BY_ID,
  GET_WALLET_BY_ID,
  GET_POSTE_BY_ID,
  GET_ACTIVITY,
  GET_TYPE_POS,
  GET_USER_PROFILE,
  GET_EXTRACT,
  GET_EXTRACT_TO_PDF,
  GET_CANALS,
  GET_PROFILE_PIC,
  GET_CA,
  GET_ALL_SERVICE_RECLAMATION,
  GET_ALL_RECLAMATION_BY_USER,
  GET_ALL_COMMENTS_RECLAMATION,
  ID_RECLAMATION,
  GET_DOCUMENTS_C,
  GET_DOCUMENTS_P,
  GET_GALLERY_PIC,
  GET_GALLERY_EMPTY,
  GET_DOCUMENTS_P_EMPTY,
  GET_DOCUMENTS_C_EMPTY,
  GET_PROFILE_PIC_EMPTY
} = require('shared/constants/ActionTypes');

const INIT_STATE = {
  service: [],
  category: [],
  supplier: [],
  reimbursed: [],
  intervenant: [],
  suppService: [],
  service_id: {},
  serviceVoucher: [],
  commissions: [],
  serviceTopup: [],
  servicePoste: [],
  serviceWallet: [],
  activity: [],
  typePos: [],
  contact: {},
  extract: [],
  totalPages: 0,
  extractToPDF: '',
  canals: [],
  profilePic: '',
  ca: [],
  serviceReclamation: [],
  allReclamation: {},
  allcomments: [],
  idreclamation: null,
  documentsC: [],
  documentsP: [],
  galleryPic: []
};
const configurationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_COMMENTS_RECLAMATION:
      return {
        ...state,
        allcomments: action.payload
      };
    case ID_RECLAMATION:
      return {
        ...state,
        idreclamation: action.payload
      };
    case GET_ALL_RECLAMATION_BY_USER:
      return {...state, allReclamation: action.payload};
    case GET_ALL_SERVICE_RECLAMATION:
      return {...state, serviceReclamation: action.payload.data};
    case GET_CA:
      return {
        ...state,
        ca: action.payload
      };
    case POST_SERVICE:
      return {
        ...state,
        service: [action.payload, ...state.category]
      };
    case POST_CATEGORY: {
      return {
        ...state,
        category: [action.payload, ...state.service]
      };
    }
    case GET_SUPPLIER: {
      return {
        ...state,
        supplier: action.payload.items
      };
    }
    case GET_INTERVENANT: {
      return {
        ...state,
        intervenant: action.payload.items
      };
    }
    case GET_SERVICE: {
      return {
        ...state,
        service: action.payload.items
      };
    }
    case GET_SERVICE_BY_ID: {
      return {
        ...state,
        serviceVoucher: action.payload.Services
      };
    }
    case GET_TOPUP_BY_ID: {
      return {
        ...state,
        serviceTopup: action.payload.Services
      };
    }
    case GET_WALLET_BY_ID: {
      return {
        ...state,
        serviceWallet: action.payload.Services
      };
    }
    case GET_POSTE_BY_ID: {
      return {
        ...state,
        servicePoste: action.payload.Services
      };
    }
    case GET_REIMBURSED: {
      return {
        ...state,
        reimbursed: action.payload.items
      };
    }
    case GET_CATEGORY: {
      return {
        ...state,
        category: action.payload.items
      };
    }
    case GET_ACTIVITY: {
      return {
        ...state,
        activity: action.payload.data
      };
    }
    case GET_CANALS:
      return {
        ...state,
        canals: action.payload.data
      };
    case GET_TYPE_POS: {
      return {
        ...state,
        typePos: action.payload.data
      };
    }
    case GET_ALL_SUPPLIER_SERVICE: {
      return {
        ...state,
        suppService: action.payload
      };
    }
    case GET_EXTRACT: {
      return {
        ...state,
        extract: action.payload.items,
        totalPages: action.payload.totalPages
      };
    }
    case GET_EXTRACT_TO_PDF: {
      return {
        ...state,
        extractToPDF: action.payload.link
      };
    }

    case GET_PROFILE_PIC_EMPTY: {
      return {
        ...state,
        profilePic: null
      };
    }
    case GET_DOCUMENTS_C_EMPTY: {
      return {
        ...state,
        documentsC: []
      };
    }
    case GET_DOCUMENTS_P_EMPTY: {
      return {
        ...state,
        documentsP: []
      };
    }
    case GET_GALLERY_EMPTY: {
      return {
        ...state,
        galleryPic: []
      };
    }

    case GET_PROFILE_PIC: {
      return {
        ...state,
        profilePic: action.payload
      };
    }
    case GET_DOCUMENTS_C: {
      return {
        ...state,
        documentsC: [...state.documentsC, action.payload]
      };
    }
    case GET_DOCUMENTS_P: {
      return {
        ...state,
        documentsP: [...state.documentsP, action.payload]
      };
    }
    case GET_GALLERY_PIC: {
      return {
        ...state,
        galleryPic: [...state.galleryPic, action.payload]
      };
    }
    case GET_USER_PROFILE: {
      return {
        ...state,
        contact: {
          email: action.payload?.profile?.email,
          phone: action.payload?.profile?.phone,
          first_name: action.payload?.profile?.first_name,
          createdAt: action.payload?.profile?.createdAt,
          last_name: action.payload?.profile?.last_name,
          adresse: action.payload?.profile?.Company?.adresse,
          city: action.payload?.profile?.Company?.city,
          zip_code: action.payload?.profile?.Company?.zip_code,
          pos: action.payload?.profile?.Company?.pos,
          username: action.payload?.user?.username,
          id: action.payload?.user?.id,
          commercial_register: action.payload?.profile?.Company?.commercial_register,
          code_comptable: action.payload?.profile?.Company?.code_comptable,
          code: action.payload?.profile?.Company?.code,
          delegation: action.payload?.profile?.Company?.delegation,
          city: action.payload?.profile?.Company?.city,
          soldeWallet: action.payload?.profile?.soldeWallet
        }
      };
    }
    default:
      return state;
  }
};

export default configurationReducer;
