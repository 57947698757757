import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const homePageConfig = [
  {
    auth: authRole.visitor,
    routes: [
      {
        path: '/dashboard',
        component: React.lazy(() => import('./Pages/home'))
      },
      {
        path: '/profile',
        component: React.lazy(() => import('./Profile/index'))
      }
    ]
  }
];
