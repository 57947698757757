import {
  GET_ALL_CATEGORIES,
  GET_ALL_CITIES,
  GET_ALL_HOTELS,
  GET_ALL_HOTELS_DJERBA,
  GET_ALL_HOTELS_HAMMAMET,
  GET_ALL_HOTELS_MONASTIR,
  GET_ALL_HOTELS_SOUSSE,
  GET_ALL_RESERVATIONS,
  GET_QUOTE_DATA_FROM_API,
  SET_CHANGE_DATE,
  SET_FOCUS_DATE,
  SET_HOTELS_FILTERS_PARAMS,
  SET_ID_CITY,
  SET_QUOTE_DATA,
  SET_SINGLE_HOTEL_DATA,
  SET_VOUCHER_DATA
} from '../../shared/constants/ActionTypes';
export const VIEW_TYPE = Object.freeze({LIST: 1, GRID: 2});
var date = new Date();
var k = new Date();
const initialSettings = {
  viewType: VIEW_TYPE.LIST,
  products: [],
  historyTransactions: [],
  totalPages: 0,
  amountDialog: false,
  productAmount: 0,
  hotels: [],
  quoteData: undefined,
  quoteApi: undefined,
  cities: [],
  categories: [],
  startDate: k,
  endDate: date.setDate(date.getDate() + 1),
  startDateF: k,
  endDateF: date.setDate(date.getDate() + 1),
  singleHotelItem: {},
  SelectedCity: {id: undefined, value: undefined},
  focusedInput: null,
  pax: '2',
  rooms: [{adult: 2, child: '', val: []}],
  hotelsHammamet: [],
  hotelsSousse: [],
  hotelsDjerba: [],
  hotelsMonastir: [],
  VoucherData: {},
  cityId: ''
};

const hotelsReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case GET_ALL_HOTELS:
      return {
        ...state,
        hotels: action.payload.Hotels.map((hotel) => ({
          ...hotel,
          city: action.payload.City
        })),
        Destination: action.payload?.Destination ? action.payload?.Destination['#text'] : 'Tunisie'
      };
    case SET_VOUCHER_DATA:
      return {
        ...state,
        VoucherData: action.payload
      };
    case GET_ALL_HOTELS_HAMMAMET:
      return {
        ...state,
        hotelsHammamet: action.payload.Hotels.map((hotel) => ({
          ...hotel,
          City: action.payload.City
        })),
        Destination: action.payload?.Destination ? action.payload?.Destination['#text'] : 'Tunisie'
      };
    case GET_ALL_HOTELS_SOUSSE:
      return {
        ...state,
        hotelsSousse: action.payload.Hotels.map((hotel) => ({
          ...hotel,
          City: action.payload.City
        })),
        Destination: action.payload?.Destination ? action.payload?.Destination['#text'] : 'Tunisie'
      };
    case GET_ALL_HOTELS_DJERBA:
      return {
        ...state,
        hotelsDjerba: action.payload.Hotels.map((hotel) => ({
          ...hotel,
          City: action.payload.City
        })),
        Destination: action.payload?.Destination ? action.payload?.Destination['#text'] : 'Tunisie'
      };
    case GET_ALL_HOTELS_MONASTIR:
      return {
        ...state,
        hotelsMonastir: action.payload.Hotels.map((hotel) => ({
          ...hotel,
          City: action.payload.City
        })),
        Destination: action.payload?.Destination ? action.payload?.Destination['#text'] : 'Tunisie'
      };
    case GET_ALL_CITIES:
      return {
        ...state,
        cities: action.payload.data
      };
    case GET_ALL_CATEGORIES:
      return {
        ...state,
        categories: action.payload.data
      };

    case SET_HOTELS_FILTERS_PARAMS:
      return {
        ...state,
        adult: action.payload.adult,
        child: action.payload.child,
        rooms: action.payload.rooms,
        startDateF: action.payload.startDate,
        endDateF: action.payload.endDate,
        pax: action.payload.pax,
        cityId: action.payload.cityId
      };
    case SET_QUOTE_DATA:
      return {
        ...state,
        quoteData: action.payload
      };
    case GET_QUOTE_DATA_FROM_API:
      return {
        ...state,
        quoteApi: action.payload
      };
    case SET_SINGLE_HOTEL_DATA:
      return {
        ...state,
        singleHotelItem: action.payload
      };
    case SET_ID_CITY:
      return {
        ...state,
        SelectedCity: action.payload
      };
    case SET_CHANGE_DATE:
      return {
        ...state,
        ...action.payload
      };
    case SET_FOCUS_DATE:
      return {
        ...state,
        focusedInput: action.payload
      };
    case GET_ALL_RESERVATIONS:
      return {
        ...state,
        reservation: action.payload.data,
        totalPages: action.payload.totalPages
      };
    default:
      return state;
  }
};

export default hotelsReducer;
