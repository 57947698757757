import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const rechargeWalletPagesConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        exact: true,
        path: '/Menu-recharge',
        component: React.lazy(() => import('./Pages/menu'))
      }
    ]
  },
  {
    auth: authRole.user,
    routes: [
      {
        exact: true,
        path: '/recharge/history',
        component: React.lazy(() => import('./Pages/history'))
      }
    ]
  },
  {
    auth: authRole.user,
    routes: [
      {
        exact: true,
        path: '/Menu-recharge/Sobflouss',
        component: React.lazy(() => import('./Pages/Sobflouss'))
      }
    ]
  },
  {
    auth: authRole.user,
    routes: [
      {
        exact: true,
        path: '/Menu-recharge/paymee',
        component: React.lazy(() => import('./Pages/Paymee'))
      }
    ]
  }
];
