import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const vipPageConfig = [
  {
    auth: authRole.onlyVisitorVip,
    routes: [
      {
        path: '/Historique-topup-vip',
        component: React.lazy(() => import('./Pages/History'))
      }
    ]
  }
];
