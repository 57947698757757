import Axios from 'axios';
import {baseUrl} from 'shared/constants/AppConst';
const token = localStorage.getItem('token');
//console.log('tokeeeeen', token);
const dataAxios = Axios.create({
  baseURL: baseUrl, //YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
    'Access-Origin': '*'
    //Authorization: 'Bearer ' + token&&token,
  }
});
dataAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    //console.log('errrrr401', err.config.url);
    const originalConfig = err.config;
    if (err.response && err.response.data.msg === 'Token is not valid') {
      //console.log('Need to logout user');
      // store.dispatch({type: LOGOUT});
    }

    // Access Token was expired
    if (err?.response?.status === 401) {
      try {
        //console.log('401');
        window.localStorage.clear();
        window.location.reload();
        //history.push('/connexion')
      } catch (_error) {
        return Promise.reject(_error);
      }
    }

    return Promise.reject(err);
  }
);
export default dataAxios;
