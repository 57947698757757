import React, {useContext, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {matchRoutes} from 'react-router-config';
import qs from 'qs';
import AppContext from './AppContext';
import {useAuthToken} from './AppHooks';
import {Loader} from '../index';
import PropTypes from 'prop-types';
import {checkPermission} from './Utils';
import {initialUrl} from '../../shared/constants/AppConst';
import {setInitialPath} from '../../redux/actions';

const AuthRoutes = ({children}) => {
  const {pathname, search} = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {routes, changeNavStyle, updateThemeStyle, updateThemeMode, setRTL} =
    useContext(AppContext);

  const [loading, user] = useAuthToken();
  const initialPath = useSelector(({settings}) => settings.initialPath);
  const currentRoute = matchRoutes(routes, pathname)[0].route;
  let isPermitted = checkPermission(currentRoute.auth, user ? user.role : null);
  console.log(pathname, 'pathnamepathnamepathname');
  console.log(initialPath, 'initialPathinitialPathinitialPath');
  useEffect(() => {
    function setInitPath() {
      if (
        initialPath === '/' &&
        [
          '/connexion',
          '/inscription',
          '/resultat',
          '/ar/resultat',
          '/contactez',
          '/FAQ',
          '/guide',
          '/blogs',
          '/guide/:id',
          '/confirm-signup',
          '/reset-password',
          '/error-pages/error-404',
          '/mot-de-passe-oublie',
          '/home.html'
        ].indexOf(pathname) === -1
      ) {
        if (isPermitted) {
          dispatch(setInitialPath(pathname));
        } else {
          dispatch(setInitialPath(undefined));
        }
      }
    }

    setInitPath();
  }, [dispatch, isPermitted, initialPath, pathname]);

  useEffect(() => {
    function handleQueryParams() {
      const query = qs.parse(search.split('?')[1]);
      if (query.layout) {
        changeNavStyle(query.layout);
      }
      if (query.mode) {
        updateThemeMode(query.mode);
      }
      if (query.rtl) {
        setRTL(true);
      }
      if (query.style) {
        updateThemeStyle(query.style);
      }
    }

    if (search) {
      handleQueryParams();
    }
  }, [changeNavStyle, updateThemeStyle, updateThemeMode, setRTL, search]);
  console.log(initialUrl, 'initialUrlinitialUrlinitialUrlinitialUrl');
  useEffect(() => {
    if (!loading) {
      if (!user && !isPermitted) {
        window.location.href = '/home.html'; // allowed route
      } else if (user && !isPermitted) {
        localStorage.clear();
        history.push('/connexion'); // Not found
      } else if (user && isPermitted) {
        if (
          pathname === '/' ||
          pathname === '/connexion' ||
          pathname === '/inscription' ||
          pathname === '/reset-password' ||
          pathname === '/home.html' ||
          pathname === '/blogs' ||
          pathname === '/error-pages/error-404' ||
          pathname === '/mot-de-passe-oublie' ||
          pathname === '/resultat' ||
          pathname === '/ar/resultat' ||
          pathname === '/contactez' ||
          pathname === '/FAQ' ||
          pathname === '/guide' ||
          pathname === '/guide/:id'
        ) {
          history.push(initialUrl);
        } else if (
          initialPath &&
          initialUrl !== initialPath &&
          (initialPath !== '/' || initialPath !== '/connexion' || initialPath !== '/inscription')
        ) {
          history.push(initialPath);
        }
      }
    }
  }, [user, loading, initialPath, isPermitted, pathname, history]);

  return loading ? <Loader /> : <>{children}</>;
};

export default AuthRoutes;

AuthRoutes.propTypes = {
  children: PropTypes.node.isRequired
};
