import {
  GET_ALL_TOPUP_TRANS,
  GET_ALL_TOPUP_OPERATORS_RETAILER,
  GET_ALL_VOUCHER_TRANSACTIONS,
  GET_ALL_OPERATORS_RETAILER,
  GET_ALL_TOPUP_STOCK,
  SET_STOCK_BY_OPERATOR,
  GET_STOCK_VOUCHER,
  GET_ALL_VOUCHER_STOCK,
  GET_EMPTY_DOCUMENTS,
  GET_HISTORY_REIMBURSED
} from 'shared/constants/ActionTypes';

const initState = {
  totalItems: 0,
  totalAmount: 0,
  totalGros: 0,
  totalDetail: 0,
  operators: [],
  topupStock: [],
  historyReimbursd: [],
  totalPages: 0,
  stockOperator: {},
  stockVouch: [],
  stock: [],
  documents: []
};

const historyReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_EMPTY_DOCUMENTS:
      return {
        ...state,
        documents: action.payload
        // totalPages: action.payload.totalPages,
      };
    case GET_ALL_VOUCHER_STOCK:
      return {
        ...state,
        stock: action.payload.items,
        totalPages: action.payload.totalPages
      };
    case GET_STOCK_VOUCHER:
      return {
        ...state,
        stockVouch: action.payload.data,
        totalAmount: action.payload.extraData.totalAmount,
        totalPages: 1
      };
    case SET_STOCK_BY_OPERATOR:
      return {
        ...state,
        totalStock: action.payload.data.reduce((a, current) => a + parseFloat(current.stock), 0),
        stockOperator: action.payload.data.reduce(function (count, currentValue) {
          return (
            count[currentValue.code]
              ? (count[currentValue.code] =
                  count[currentValue.code] + parseFloat(currentValue.stock))
              : (count[currentValue.code] = parseFloat(currentValue.stock)),
            count
          );
        }, {})
      };
    case GET_ALL_TOPUP_STOCK:
      return {
        ...state,
        topupStock: action.payload.data,
        totalPages: action.payload.totalPages
      };
    case GET_ALL_OPERATORS_RETAILER:
      return {
        ...state,
        operators: action.payload.items
      };
    case GET_HISTORY_REIMBURSED:
      return {
        ...state,
        historyReimbursd: action.payload.data,
        totalPages: action.payload.totalPages
      };
    case GET_ALL_VOUCHER_TRANSACTIONS:
      return {
        ...state,
        totalItems: action.payload.totalItems,
        totalAmount: action.payload.totalAmount ? action.payload.totalAmount : 0
      };
    case GET_ALL_TOPUP_TRANS:
      return {
        ...state,
        totalItems: action.payload.totalItems ? action.payload.totalItems : 0,
        totalAmount: action.payload.totalAmount ? action.payload.totalAmount : 0,
        totalGros: action.payload.extraData?.totalGros,
        totalDetail: action.payload.extraData?.totalDetails
      };
    case GET_ALL_TOPUP_OPERATORS_RETAILER:
      return {
        ...state,
        operators: action.payload.data.map((item) =>
          item.Type.type === 'api_externe'
            ? {...item, label: (item.label += ' API externe')}
            : item.Type.type === 'api_interne'
            ? {...item, label: (item.label += ' API interne')}
            : item
        )
      };
    default:
      return state;
  }
};

export default historyReducer;
