import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
var dt = new Date();
var curday = function (sp) {
  //today = new Date();
  var dd = dt.getDate();
  var mm = dt.getMonth() + 1; //As January is 0.
  var yyyy = dt.getFullYear();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;
  return yyyy + sp + mm + sp + dd;
};
const useStyles = makeStyles((theme) => ({
  margin: {
    // marginRight: theme.spacing(15),
    marginTop: theme.spacing(5),

    [theme.breakpoints.down('md')]: {
      display: 'none'
      // marginRight: theme.spacing(1),
      // marginTop: theme.spacing(1),
      // width: '100%',
    }
  },
  text: {
    fontSize: '18px',

    color: '#003255',

    fontFamily: 'Poppins',
    fontStyle: 'italic'
  },
  date: {
    fontSize: '18px',

    color: '#003255',

    fontFamily: 'Poppins',
    fontStyle: 'italic'
  }
}));

export default function HeaderDate() {
  const [date, setDate] = React.useState(curday('/'));
  const classes = useStyles();
  return (
    <Box className={classes.margin}>
      <span className={classes.text}>DATE:</span> <span className={classes.date}> {date}</span>
    </Box>
  );
}
