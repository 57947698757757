import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {useSelector} from 'react-redux';
import IntlMessages from '@crema/utility/IntlMessages';

const ColorButton = withStyles((theme) => ({
  root: {
    color: '#FBFBFB',
    background: 'linear-gradient(left, #EE0000, #BA0000)',
    fontWeight: 'normal'
  }
}))(Button);
const useStyles = makeStyles((theme) => ({
  margin: {
    //marginRight: theme.spacing(25),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
      width: '100%'
    }
  },
  text: {
    fontSize: '18px',
    fontWeight: '600',
    color: '#000000',
    fontFamily: 'Poppins',
    letterSpacing: 2
  },
  text1: {
    fontSize: '18px',
    fontWeight: '600',
    color: '#000000',
    letterSpacing: 1,
    fontFamily: 'Poppins',
    fontStyle: 'italic',
    letterSpacing: 2
  },
  btn1: {
    padding: '4px 20px 4px 20px ',
    marginBottom: '10px',
    color: '#000000',
    background: 'transparent  ',
    fontWeight: 'normal',
    height: '45px',
    width: '252px',
    border: '1px solid #DBD7D7',
    boxShadow: ' 0px 2px 4px rgba(100,100,100,0.5)',
    borderRadius: '20px'
  },
  btn2: {
    padding: '4px 20px 4px 20px ',
    marginBottom: '10px',
    color: '#000000',
    background: 'transparent  ',
    fontWeight: 'normal',
    height: '45px',
    width: '252px',
    border: '1px solid #DBD7D7',
    boxShadow: ' 0px 2px 4px rgba(100,100,100,0.5)',
    borderRadius: '20px '
  }
}));

export default function TotalStock() {
  const classes = useStyles();
  const balance = useSelector(({auth}) => auth.balance);
  console.log('balaaaaaaaaaaaaaance', balance);
  return (
    <div>
      <div
        variant='contained'
        color='primary'
        className={classes.margin}
        messageId='navbar.total.stock'>
        <Button className={balance > 100 ? classes.btn1 : classes.btn2}>
          <span className={classes.text1}>
            <IntlMessages id='solde.btn' /> :
          </span>{' '}
          <span className={classes.text}>{balance}</span> <IntlMessages id='common.tnd' />
        </Button>
      </div>
    </div>
  );
}
