//API
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const TOGGLE_APP_DRAWER = 'toggle_app_drawer';
export const UPDATING_CONTENT = 'updating_content';

//APP SETTING
export const TOGGLE_NAV_COLLAPSED = 'toggle_nav_collapsed';
export const SET_INITIAL_PATH = 'set_initial_path';
//BLOGS
export const GET_ALL_BLOGS_LIST = 'get_all_blogs_list';
export const GET_ALL_BLOGS_DELETED = 'get_all_blogs_deleted';
export const SET_NEW_BLOG = 'set_new_blog';
export const GET_BLOG_ID = 'get_blog_id';
export const UPDATE_BLOG = 'update_blog';
export const ACTIVATE_BLOG = 'activate_blog';

//AUTH0
export const UPDATE_AUTH_USER = 'update_auth_user';
export const SET_AUTH_TOKEN = 'set_auth_token';
export const SIGNOUT_AUTH_SUCCESS = 'signout_auth_success';

//ANALYTICS-DASHBOARD
export const GET_ANALYTICS_DATA = 'get_analytics_data';
//ECOMMERCE-DASHBOARD
export const GET_ECOMMERCE_DATA = 'get_ecommerce_data';

//ACADEMY-DASHBOARDDemandeRemboursement
export const GET_ACADEMY_DATA = 'get_academy_data';
export const GET_HISTORY_REIMBURSED = 'GET_HISTORY_REIMBURSED';
export const GET_HISTORY_REIMBURSED_FAILED = 'GET_HISTORY_REIMBURSED_FAILED';

//CRM-DASHBOARD
export const GET_CRM_DATA = 'get_crm_data';

//CRYPTO-DASHBOARD
export const GET_CRYPTO_DATA = 'get_crypto_data';

//CRYPTO-DASHBOARD
export const GET_HC_DATA = 'get_hc_data';

//METRICS-DASHBOARD
export const GET_METRICS_DATA = 'get_metrics_data';

//WIDGETS_DASHBOARD
export const GET_WIDGETS_DATA = 'get_widgets_data';

//MAIL-APP
export const GET_MAIL_LIST = 'get_mail_list';
export const GET_FOLDER_LIST = 'get_folder_list';
export const GET_LABEL_LIST = 'get_label_list';
export const TOGGLE_MAIL_DRAWER = 'toggle_mail_drawer';
export const COMPOSE_MAIL = 'compose_mail';
export const GET_MAIL_DETAIL = 'get_mail_detail';
export const UPDATE_MAIL_FOLDER = 'update_mail_folders';
export const UPDATE_MAIL_LABEL = 'update_mail_label';
export const UPDATE_STARRED_STATUS = 'update_starred_status';
export const UPDATED_MAIL_DETAIL = 'updated_mail_detail';
export const CHANGE_READ_STATUS = 'change_read_status';
export const GET_CONNECTION_LIST = 'get_connection_list';
export const NULLIFY_MAIL = 'nullify_mail';

//TODO-APP
export const GET_TASK_LIST = 'get_task_list';
export const CREATE_NEW_TASK = 'create_new_task';
export const TOGGLE_TODO_DRAWER = 'toggle_todo_drawer';
export const GET_TODO_FOLDER_LIST = 'GET_TODO_FOLDER_LIST';
export const GET_TODO_LABEL_LIST = 'GET_TODO_LABEL_LIST';
export const GET_TODO_STATUS_LIST = 'GET_TODO_STATUS_LIST';
export const GET_TODO_PRIORITY_LIST = 'GET_TODO_PRIORITY_LIST';
export const UPDATE_TASK_FOLDER = 'UPDATE_TASK_FOLDER';
export const UPDATE_TASK_LABEL = 'UPDATE_TASK_LABEL';
export const UPDATE_TASK_STARRED_STATUS = 'UPDATE_TASK_STARRED_STATUS';
export const GET_TASK_DETAIL = 'GET_TASK_DETAIL';
export const UPDATE_TASK_DETAIL = 'UPDATE_TASK_DETAIL';
export const GET_TODO_STAFF_LIST = 'GET_TODO_STAFF_LIST';

//CONTACT_APP
export const GET_CONTACT_LIST = 'GET_CONTACT_LIST';
export const GET_CONTACT_FOLDER_LIST = 'GET_CONTACT_FOLDER_LIST';
export const GET_CONTACT_LABEL_LIST = 'GET_CONTACT_LABEL_LIST';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const UPDATE_CONTACT_LABEL = 'UPDATE_CONTACT_LABEL';
export const UPDATE_CONTACT_STARRED_STATUS = 'UPDATE_CONTACT_STARRED_STATUS';
export const GET_CONTACT_DETAIL = 'GET_CONTACT_DETAIL';
export const TOGGLE_CONTACT_DRAWER = 'TOGGLE_CONTACT_DRAWER';
export const UPDATE_CONTACT_DETAIL = 'UPDATE_CONTACT_DETAIL';
export const CREATE_NEW_CONTACT = 'CREATE_NEW_CONTACT';

//SCRUMBOARD_APP
export const GET_MEMBER_LIST = 'GET_MEMBER_LIST';
export const GET_SCRUM_LABEL_LIST = 'GET_SCRUM_LABEL_LIST';
export const GET_BOARDS = 'GET_BOARDS';
export const GET_BOARD_DETAIL = 'GET_BOARD_DETAIL';
export const ADD_BOARD_LIST = 'ADD_BOARD_LIST';
export const ADD_LIST_CARD = 'ADD_LIST_CARD';
export const EDIT_LIST_CARD = 'EDIT_LIST_CARD';
export const DELETE_LIST_CARD = 'DELETE_LIST_CARD';
export const DELETE_BOARD = 'DELETE_BOARD';
export const ADD_NEW_BOARD = 'ADD_NEW_BOARD';
export const DELETE_LIST = 'DELETE_LIST';
export const EDIT_BOARD_DETAIL = 'EDIT_BOARD_DETAIL';
export const EDIT_BOARD_LIST = 'EDIT_BOARD_LIST';

//CHAT_APP
export const GET_CONNECTIONS_LIST = 'get_connections_list';
export const GET_USER_MESSAGES = 'get_user_messages';
export const ADD_NEW_MESSAGE = 'add_new_message';
export const EDIT_MESSAGE = 'edit_message';
export const DELETE_MESSAGE = 'delete_message';
export const DELETE_USER_MESSAGES = 'delete_user_messages';
export const TOGGLE_CHAT_DRAWER = 'toggle_chat_drawer';
export const SELECT_USER = 'select_user';

//WALL_APP
export const GET_WALL_DATA = 'get_wall_data';
export const GET_FEED_POSTS = 'get_feed_posts';
export const CREATE_NEW_POST = 'create_new_post';
export const UPDATE_POST = 'update_post';

//USER_LIST
export const GET_USER_LIST = 'GET_USER_LIST';

//ECOMMERCE_LIST
export const GET_ECOMMERCE_LIST = 'get_ecommerce_list';
export const SET_PRODUCT_VIEW_TYPE = 'set_product_view_type';
export const SET_FILTER_DATA = 'set_filter_data';
export const SET_PRODUCT_DATA = 'set_product_data';
export const GET_RECENT_ORDER = 'get_recent_order';
export const GET_CUSTOMERS = 'get_customers';
export const ADD_CART_ITEM = 'add_cart_item';
export const REMOVE_CART_ITEM = 'remove_cart_item';
export const UPDATE_CART_ITEM = 'update_cart_item';
export const SET_CART_ITEMS = 'set_cart_items';

//CK-EDITOR
export const GET_BALLOON_BLOCK_DATA = 'get_balloon_block_data';
export const UPDATE_BALLOON_BLOCK_DATA = 'update_balloon_block_data';
export const GET_BALLOON_DATA = 'get_balloon_data';
export const UPDATE_BALLOON_DATA = 'update_balloon_data';
export const GET_CLASSIC_DATA = 'get_classic_data';
export const UPDATE_CLASSIC_DATA = 'update_classic_data';
export const GET_INLINE_DATA = 'get_inline_data';
export const UPDATE_INLINE_DATA = 'update_inline_data';
export const GET_DOCUMENT_DATA = 'get_document_data';
export const UPDATE_DOCUMENT_DATA = 'update_document_data';
export const GET_CUSTOM_DATA = 'get_custom_data';
export const UPDATE_CUSTOM_DATA = 'update_custom_data';

//GALLERY
export const GET_GALLERY_PHOTO = 'get_gallery_photo';

//POST_DATA
export const POST_DATA = 'post_data';

//configuration
export const POST_SERVICE = 'post_service';
export const POST_CATEGORY = 'post_category';
export const GET_SUPPLIER = 'get_supplier';
export const GET_SERVICE = 'get_service';
export const GET_REIMBURSED = 'get_reimbursed';
export const GET_INTERVENANT = 'get_intervenant';
export const GET_CATEGORY = 'get_category';
export const GET_ALL_SUPPLIER_SERVICE = 'get_supplier_service';
export const GET_SERVICE_BY_ID = 'get_service_id';
export const GET_TOPUP_BY_ID = 'get_topup_id';
export const GET_WALLET_BY_ID = 'get_wallet_id';
export const GET_POSTE_BY_ID = 'get_poste_id';

export const GET_ALL_FACIAL = 'get_all_facial';
export const PASSER_COMMANDE_VOUCHER = 'passer_commande_voucher';
export const GET_ALL_OPERATORS = 'get_all_operators';
export const SEND_VOUCHER_COMMAND = 'send_voucher_command';
export const GET_ALL_SERVICE_SUPPLIER = 'get_all_service_supplier';
export const CREATE_OBJECT_FROM_SERV_SUPP = 'create_object_from_serv_supp';
export const GET_ALL_HISTORY_VOUCHER = 'get_all_voucher_history';
export const GET_ALL_HISTORY_TOPUP = 'GET_ALL_HISTORY_TOPUP';
export const GET_USER_BALANCE = 'get-user_balance';
export const GET_VOUCHER_STATUS = 'get_voucher_status';
export const SHOW_DIALOG = 'show_dialog';
export const HIDE_DIALOG = 'hide_dialog';
export const SET_VOUCHER_STATUS_FALSE = 'set_voucher_status_false';
export const GET_NB_PRINTED = 'GET_NB_PRINTED';
export const SEY_EMPTY = 'set_empty';
export const GET_USER_STATS = 'get_user_stats';
export const GET_USER_STATS_BYMONTH = 'get_user_stats_by_month';
export const GET_ACTIVITY = 'get_activity';
export const GET_TYPE_POS = 'get_type';
export const GET_CATEGORY_PROFILE = 'get_category_profile';
export const GET_USER_COMMISSIONS = 'get_user_commissions';
export const GET_ALL_HISTORY_VIREMENT = 'get_all_history_virement_versement';
export const GET_ALL_HISTORY_POSTE_PAYMENT = 'get_all_history_poste_payment';
export const GET_ALL_TOTAL_ITEMS_BILL = 'get_all_totla_items_bill';
export const GET_ALL_HISTORY_POSTE_AFFILIATION = 'get_all_history_poste_affiliation';

export const GET_ALL_BILLS = 'GET_ALL_BILLS';
export const GET_ALL_BILLS_STEG = 'GET_ALL_BILLS_STEG';

export const GET_ALL_HISTORY_POSTE_RECHARGE = 'get_all_history_poste_recharge';
export const GET_USER_PROFILE = 'get_user_profile';
export const GET_EXTRACT = 'get_extract';
export const GET_EXTRACT_TO_PDF = 'get_extract_to_pdf';
export const GET_VOUCHER_PDF = 'get_voucher_pdf';
export const GET_ALL_FEED_TYPES = 'get_all_feed_types';
export const GET_CANALS = 'get_canals';
export const GET_ALL_TOPUP_OPERATORS = 'get_all_topup_operators';
export const CONSULTATION_INVOICE = 'CONSULTATION_INVOICE';
export const GET_ALL_TYPE_OPERATOR = 'get_all_type_operator';
export const SET_DIALOG_RESULT_FALSE = 'set_dialog_result_false';
export const GET_DIALOG_STATUS = 'get_dialog_status';
export const GET_PROFILE_PIC = 'get_profile_pic';
export const CLICKTOPAY_ORDER_ID = 'clicktopay_order_id';
export const GET_ALL_MINTROUTE_PRODUCTS = 'get_all_mintroute_products';
export const GET_ALL_MINTROUTE_HISTORY = 'get_all_mintroute_history';
export const SET_MINTROUTE_AMOUNT = 'set_mintroute_product_amount';
export const SET_MINTROUTE_AMOUNT_FALSE = 'set_mintroute_product_amount_false';
export const GET_ALL_MINTROUTE_TRANS = 'get_all_mintroute_transactions';
export const SET_IS_FIRST_CONNECTION = 'set_is_first_connection';
export const GET_ALL_POSTE_PAYMENT_ORGANISMS = 'get_all_payment_organisms';
export const GET_ALL_POSTE_PAYMENT_INVOICES = 'get_all_payment_invoices';
export const SET_MINTROUTE_VOUCHER_PRINT = 'set_mintroute_voucher_print';
export const SET_RECIEVED_CODE_FROM_EMAIL = 'set_recieved_code_from_email';
export const SET_OPEN_CHANGE_PASSWORD_DIALOG = 'set_change_password_dialog';
export const GET_ALL_COMMERCIALS = 'get_all_commercials';
export const GET_ALL_HOTELS = 'get_all_hotels';
export const GET_ALL_CITIES = 'get_all_cities';
export const SET_HOTELS_FILTERS_PARAMS = 'set_hotes_filters_params';
export const SET_QUOTE_DATA = 'set_quote_data';
export const GET_QUOTE_DATA_FROM_API = 'get_quote_data_from_api';
export const GET_ALL_CATEGORIES = 'get_all_categories';
export const SET_SINGLE_HOTEL_DATA = 'set_single_hotel_data';
export const SET_ID_CITY = 'set_id_city';
export const SET_CHANGE_DATE = 'set_change_date';
export const SET_FOCUS_DATE = 'set_focus_date';
export const GET_ALL_RESERVATIONS = 'get_all_reservations';
export const GET_ALL_HOTELS_SOUSSE = 'get_all_hotels_sousse';
export const GET_ALL_HOTELS_HAMMAMET = 'get_all_hotels_hammamet';
export const SET_VOUCHER_DATA = 'SET_VOUCHER_DATA';
export const GET_ALL_HOTELS_DJERBA = 'get_all_hotels_djerba';
export const GET_ALL_HOTELS_MONASTIR = 'get_all_hotels_monastir';
export const GET_ALL_TOPNET_HISTORY = 'get_all_topnet_history';
export const SET_DATA_PRINT_STATUS_FALSE = 'set_data_print_status_false';
export const SET_DATA_PRINT_STATUS_TRUE = 'set_data_print_status_true';
export const GET_SOURCE_LIST = 'get_source_list';
export const GET_TYPE_PRODUCT = 'get_type_product';

export const GET_CA = 'get_ca';

export const REGISTER_FAIL = 'REGISTER_FAIL';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const FAIL = 'FAIL';
export const LOGOUT = 'LOGOUT';

export const POST_RECLAMATION_SUCCESS = 'POST_RECLAMATION_SUCCESS';
export const POST_RECLAMATION_FAIL = 'POST_RECLAMATION_FAIL';
export const GET_SERVICES = 'GET_SERVICES';
export const GET_PERIORITE = 'GET_PERIORITE';
export const GET_ALL_RECLAMATIONS_USER = 'GET_ALL_RECLAMATIONS_USER';

export const GET_INFORMATION_ID_RECLAMATION = 'GET_INFORMATION_ID_RECLAMATION';

export const GET_STATUS = 'GET_STATUS';

export const LOADING_SUCCESS = 'LOADING_SUCCESS';
export const GET_ALL_MINTROUTE_BRAND = 'get_all_mintroute_brand';
export const POST_NEW_COMMENT = 'POST_NEW_COMMENT';
export const SHOW_MESSAGE_SUBSCRIPTION = 'show_message_subscription';
export const GET_ENABLED_SERVICES = 'get_enabled_services';
export const GET_ALL_MINTROUTE_GUIDE = 'get_all_mintroute_guide';
export const GET_MINTROUTE_GUIDE_DETAIL = 'get_mintroute_guide_detail';
export const GET_ALL_REGIONS = 'GET_ALL_REGIONS';
export const GET_INTERNET_SABBA_DATA = 'get_internet_sabba_data';
export const GET_ALL_ACTIVITY = 'GET_ALL_ACTIVITY';
export const POST_ORDER = 'POST_ORDER';
export const GET_ORDERS = 'GET_ORDERS';
export const GET_ALL_SERVICE_RECLAMATION = 'GET_ALL_SERVICE_RECLAMATION';
export const GET_ALL_RECLAMATION_BY_USER = 'GET_ALL_RECLAMATION_BY_USER';
export const GET_ALL_COMMENTS_RECLAMATION = 'GET_ALL_COMMENTS_RECLAMATION';
export const ID_RECLAMATION = 'ID_RECLAMATION';
export const GET_ALL_TOPUP_TRANS = 'GET_ALL_TOPUP_TRANS';
export const GET_ALL_TOPUP_OPERATORS_RETAILER = 'GET_ALL_TOPUP_OPERATORS_RETAILER';
export const GET_ALL_VOUCHER_TRANSACTIONS = 'GET_ALL_VOUCHER_TRANSACTIONS';
export const GET_ALL_OPERATORS_RETAILER = 'GET_ALL_OPERATORS_RETAILER';
export const GET_ALL_TOPUP_STOCK = 'GET_ALL_TOPUP_STOCK';
export const SET_STOCK_BY_OPERATOR = 'SET_STOCK_BY_OPERATOR';
export const GET_ALL_VOUCHER_STOCK = 'GET_ALL_VOUCHER_STOCK';
export const GET_EMPTY_DOCUMENTS = 'GET_EMPTY_DOCUMENTS';
export const GET_STOCK_VOUCHER = 'GET_STOCK_VOUCHER';
//Module Reclamation
export const GET_ALL_RECLAMATION = 'GET_ALL_RECLAMATION';

export const GET_DOCUMENTS_C = 'get_documents_c';
export const GET_DOCUMENTS_P = 'get_documents_P';
export const GET_DOCUMENTS_C_EMPTY = 'get_documents_c_empty';
export const GET_DOCUMENTS_P_EMPTY = 'get_documents_P_empty';
export const GET_PROFILE_PIC_EMPTY = 'get_empty_profile_pic';
export const GET_GALLERY_EMPTY = 'get_gallery_empty';
export const GET_ONE_COMPANY = 'get_one_company';
