import dataAxios from '@crema/services/data/axiosData';
import axios from 'axios';
import {useHistory} from 'react-router';
import {baseUrl} from '../../../../shared/constants/AppConst';
// const history = useHistory();
const jwtAxios = axios.create({
  baseURL: baseUrl + '/api', //YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
});
jwtAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    console.log('errrrr401', err);
    if (err.response && err.response.data.msg === 'Token is not valid') {
      console.log('Need to logout user');
      // store.dispatch({type: LOGOUT});
    }

    // Access Token was expired
    if (err.response.status === 401) {
      try {
        //console.log('401');
        window.localStorage.clear();
        //history.push('/connexion');
        // const rs = await instance.post("/auth/refreshtoken", {
        //   refreshToken: TokenService.getLocalRefreshToken(),
        // });

        // const { accessToken } = rs.data;
        // TokenService.updateLocalAccessToken(accessToken);

        // return instance(originalConfig);
      } catch (_error) {
        return Promise.reject(_error);
      }
    }

    return Promise.reject(err);
  }
);

export const setAuthToken = (token) => {
  if (token) {
    jwtAxios.defaults.headers.common['x-auth-token'] = token;
    dataAxios.defaults.headers.common = {Authorization: `Bearer ${token}`};
    localStorage.setItem('token', token);
  } else {
    delete jwtAxios.defaults.headers.common['x-auth-token'];
    delete dataAxios.defaults.headers.common['Authorization'];
    localStorage.removeItem('token');
  }
};

export default jwtAxios;
