import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const raccourciPagesConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        path: '/raccourci',
        component: React.lazy(() => import('./Pages/index'))
      }
    ]
  }
];
