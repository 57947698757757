import {convertServiceSupplierArrayToObject} from 'shared/constants/helpers';
import {
  CREATE_OBJECT_FROM_SERV_SUPP,
  GET_ALL_FACIAL,
  GET_ALL_HISTORY_VOUCHER,
  GET_ALL_OPERATORS,
  GET_ALL_SERVICE_SUPPLIER,
  GET_VOUCHER_PDF,
  GET_VOUCHER_STATUS,
  SET_VOUCHER_STATUS_FALSE,
  SEY_EMPTY,
  GET_ALL_HISTORY_TOPUP,
  GET_ALL_TOPUP_OPERATORS,
  GET_ALL_TYPE_OPERATOR,
  GET_DIALOG_STATUS,
  SET_DIALOG_RESULT_FALSE,
  GET_INTERNET_SABBA_DATA,
  GET_ORDERS
} from '../../shared/constants/ActionTypes';

const initialSettings = {
  facials: [],
  operators: [],
  idOrange: '',
  idOoredoo: '',
  idTT: '',
  facialsIDs: {},
  operatorsID: {},
  servSuppObj: {},
  historyVoucher: [],
  historyTopup: [],
  totalPages: 0,
  voucherpostStatus: false,
  voucherPDFLink: '',
  topupOp: [],
  typeOperator: '',
  sabba: [],
  allOrdersHistory: {}
};

const voucherReducer = (state = initialSettings, action) => {
  const f = {...state.facialsIDs};

  switch (action.type) {
    case GET_ALL_FACIAL:
      let v1, v2, v3, v4;
      action.payload.map((item, index) =>
        item.label === '1.000'
          ? (v1 = item.value)
          : item.label === '2.000'
          ? (v2 = item.value)
          : item.label === '5.000'
          ? (v3 = item.value)
          : item.label === '10.000'
          ? (v4 = item.value)
          : null
      );
      return {
        ...state,
        facials: action.payload,
        facialsIDs: {
          idFacial1: v1,
          idFacial2: v2,
          idFacial5: v3,
          idFacial10: v4
        }
      };

    case GET_ALL_OPERATORS:
      let vo, vt, vr, va, vl;
      action.payload.map((item, index) =>
        item.label === 'orange'
          ? (vo = item.label)
          : item.label === 'telecom'
          ? (vt = item.label)
          : item.label === 'ooredoo'
          ? (vr = item.label)
          : item.code === '40'
          ? (vl = item.label)
          : item.code === '50'
          ? (va = item.label)
          : null
      );
      return {
        ...state,
        operators: action.payload,
        operatorsID: {
          idOrange: vo,
          idTT: vt,
          idOoredoo: vr,
          idLyca: vl,
          idAsel: va
        }
      };
    case GET_ALL_SERVICE_SUPPLIER: {
      return {
        ...state,
        typeService: action.payload
      };
    }
    case GET_ALL_TYPE_OPERATOR: {
      return {
        ...state,
        typeOperator: action.payload.id
      };
    }
    case GET_ALL_TOPUP_OPERATORS: {
      return {
        ...state,
        topupOp: action.payload
      };
    }

    case GET_ORDERS: {
      return {
        ...state,
        allOrdersHistory: action.payload.data
      };
    }
    case CREATE_OBJECT_FROM_SERV_SUPP: {
      return {
        ...state,
        servSuppObj: convertServiceSupplierArrayToObject(action.payload, 'code')
      };
    }
    case GET_ALL_HISTORY_VOUCHER: {
      return {
        ...state,
        historyVoucher: action.payload.data,
        totalPages: action.payload.totalPages
      };
    }
    case GET_ALL_HISTORY_TOPUP: {
      return {
        ...state,
        historyTopup: action.payload.data,
        totalPagesTop: action.payload.totalPages
      };
    }
    case SEY_EMPTY: {
      return {
        ...state,
        historyVoucher: [],
        totalPages: 0
      };
    }
    case GET_VOUCHER_STATUS: {
      return {
        ...state,
        voucherpostStatus: action.payload
      };
    }
    case GET_DIALOG_STATUS: {
      return {
        ...state,
        dilogResultStatus: action.payload
      };
    }
    case SET_VOUCHER_STATUS_FALSE: {
      return {
        ...state,
        voucherpostStatus: false
      };
    }
    case SET_DIALOG_RESULT_FALSE: {
      return {
        ...state,
        dilogResultStatus: false
      };
    }
    case GET_VOUCHER_PDF: {
      return {
        ...state,
        voucherPDFLink: action.payload?.download_path.slice(1),
        voucherPDF60Link: action.payload?.download_path_60mm.slice(1),
        voucherCSVLink: action.payload?.download_path_csv.slice(1),
        voucherId: action.payload.id
      };
    }
    case GET_INTERNET_SABBA_DATA: {
      return {
        ...state,
        sabba: action.payload.data
      };
    }
    default:
      return state;
  }
};

export default voucherReducer;
