import {
  GET_ALL_POSTE_PAYMENT_INVOICES,
  GET_ALL_POSTE_PAYMENT_ORGANISMS,
  GET_ALL_BILLS,
  GET_ALL_TOPNET_HISTORY,
  GET_NB_PRINTED,
  GET_ALL_BILLS_STEG
} from '../../shared/constants/ActionTypes';

const initialSettings = {
  organisms: [],
  invoice: [],
  bills: {},
  totalPages: 0,
  topnetHistory: [],
  topnetItems: [],
  nb_printed: -1
};

const invoicesReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case GET_ALL_POSTE_PAYMENT_ORGANISMS:
      return {
        ...state,
        organisms: action.payload.data,
        totalPages: action.payload.totalPages
      };
    case GET_ALL_POSTE_PAYMENT_INVOICES:
      return {
        ...state,
        invoice: action.payload.data,
        totalPages: action.payload.totalPages
      };
    case GET_ALL_BILLS:
      return {
        ...state,
        bills: action.payload
      };
    case GET_ALL_BILLS_STEG:
      return {
        ...state,
        billsApi: action.payload
      };
    case GET_NB_PRINTED:
      return {
        ...state,
        nb_printed: action.payload
      };
    case GET_ALL_TOPNET_HISTORY:
      return {
        ...state,
        topnetHistory: action.payload.items,
        topnetItems: action.payload,
        totalPages: action.payload.totalPages
      };
    default:
      return state;
  }
};

export default invoicesReducer;
