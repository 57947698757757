import React from 'react';
import {authRole} from 'shared/constants/AppConst';

export const parametersPageConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        path: '/change-password',
        component: React.lazy(() => import('./Pages/ChangePwd'))
      },
      {
        path: '/balanceTransfert',
        component: React.lazy(() => import('./Pages/BalanceTransfert'))
      },
      {
        path: '/extrait',
        component: React.lazy(() => import('./Pages/Extract'))
      },
      {
        path: '/contact',
        component: React.lazy(() => import('./Pages/Contact'))
      },
      {
        path: '/historyReclamation',
        component: React.lazy(() => import('./Pages/HistoryReclamation'))
      },
      {
        path: '/Liste-reclamations/comments',
        component: React.lazy(() => import('./Pages/Comments'))
      },

      {
        path: '/info',
        component: React.lazy(() => import('./Pages/info'))
      }
    ]
  }
];
