// import dataAxios from '@crema/services/data/axiosData';
import dataAxios from '@crema/services/data/axiosData';
import {appIntl} from '@crema/utility/Utils';
import Axios from 'axios';
import {
  CLICKTOPAY_ORDER_ID,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_SOURCE_LIST,
  SET_INITIAL_PATH,
  SHOW_MESSAGE,
  TOGGLE_NAV_COLLAPSED
} from '../../shared/constants/ActionTypes';
import {fetchError} from './Common';

export const toggleNavCollapsed = () => {
  return (dispatch) => dispatch({type: TOGGLE_NAV_COLLAPSED});
};
export const onGetSourceList = (param) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dataAxios
      .get('/user-management/source', {params: param})
      .then((res) => {
        if (res.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: GET_SOURCE_LIST,
            payload: res.data.data
          });
        } else {
          dispatch(fetchError(messages['message.somethingWentWrong']));
        }
      })
      .catch((error) => {
        dispatch(fetchError());
      });
  };
};
export const setInitialPath = (initialPath) => {
  return (dispatch) => dispatch({type: SET_INITIAL_PATH, payload: initialPath});
};
// export const clickToPayPayment = (body) => {
//   const {messages} = appIntl();

//   return (dispatch) => {
//     dispatch({type: FETCH_START});
//     Axios.get('https://ipay.clictopay.com/payment/rest/register.do', {
//       params: body,
//     })
//       .then((res) => {
//         //console.log(res, 'res from');
//         if (!res.data.formUrl) {
//           dispatch({type: FETCH_ERROR, payload: res.data.errorMessage});
//         } else {
//           dispatch({
//             type: FETCH_SUCCESS,
//           });
//           window.open(res.data.formUrl, '_blank');
//         }
//       })
//       .catch((error) => {
//                   dispatch(fetchError());

//       });
//   };
// };
