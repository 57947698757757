import React from 'react';
import List from '@material-ui/core/List';

import routesConfig from '../../../../modules/routesConfig';
import VerticalCollapse from './VerticalCollapse';
import VerticalItem from './VerticalItem';
import VerticalNavGroup from './VerticalNavGroup';
import useStyles from './VerticalItem.style';
import {Box} from '@material-ui/core';
import AppLogo from 'shared/components/AppLogo';

const Navigation = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.sectionMobile}>
        <AppLogo />
      </Box>
      <List>
        {routesConfig.map((item) => (
          <React.Fragment key={item.id}>
            {item.type === 'group' && <VerticalNavGroup item={item} level={0} />}

            {item.type === 'collapse' && <VerticalCollapse item={item} level={0} />}

            {item.type === 'item' && <VerticalItem item={item} level={0} />}
          </React.Fragment>
        ))}
      </List>
    </>
  );
};

export default Navigation;
